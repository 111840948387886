import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';

import './src/styles/reset.css';
import './src/styles/global.css';
import { withPrefix } from 'gatsby';

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  document.head.appendChild(script);
};

export const onPreRouteUpdate = () => {
  addScript(withPrefix('mailerlite.js'));
};
